import React, { useRef } from "react";
import { IoMdEye } from "react-icons/io";
import "./cardwallet.scss";

const Cardwallet = () => {
  const cardRef = useRef(null); // Create a ref for the card

//   useEffect(() => {
//     let toggled = false; // Flag to track toggle state

//     const handleClick = () => {
//       toggled = !toggled; // Toggle the state

//       const moneyEl = document.querySelector(".money img");
//       const cardCreditEl = document.querySelector(".card-credit img");
//       const texts = document.querySelectorAll(".tex span");
//       const total = document.querySelector(".total span:nth-child(2)");

//       const transformSettings = toggled
//         ? "translateY(100px) scale(0.5)"
//         : "translateY(0) scale(1)";
//       const opacityValue = toggled ? 0 : 1;
//       const textTransform = toggled
//         ? "translateY(-200px) scale(0.5)"
//         : "translateY(0)";

//       if (moneyEl && cardCreditEl && texts.length > 0) {
//         moneyEl.style.transform = `${transformSettings}`;
//         moneyEl.style.opacity = opacityValue;
//         cardCreditEl.style.transform = transformSettings;
//         cardCreditEl.style.opacity = opacityValue;

//         texts.forEach((text) => {
//           text.style.transform = textTransform;
//           text.style.opacity = opacityValue;
//         });

//         total.textContent = toggled ? "200,505.24" : "1,505.24";
//       }
//     };

//     // Attach the event listener to the card element
//     const cardEl = cardRef.current;
//     if (cardEl) {
//       cardEl.addEventListener("click", handleClick);
//     }

//     // Cleanup the event listener on component unmount
//     return () => {
//       if (cardEl) {
//         cardEl.removeEventListener("click", handleClick);
//       }
//     };
//   }, []);

  return (
    <div className="cardwallet"  ref={cardRef}>
      <svg
        style={{
          position: "absolute",
          marginLeft: "50px",
          display: "flex",
        }}
        width="100%"
        height="100vh"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#ffffff" stop-opacity="0.4" />
            <stop offset="50%" stop-color="#ffffff" stop-opacity="1" />
            <stop offset="100%" stop-color="#ffffff" stop-opacity="0.4" />
          </linearGradient>
        </defs>
        <path
          d="M170,0 Q200,140 800,30"
          stroke="url(#gradientStroke)"
          fill="none"
          strokeWidth="3"
        />
      </svg>
      <svg
        style={{ position: "absolute", zIndex:'-1'}}
        width="100%"
        height="100vh"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#ffffff" stop-opacity="0" />
            <stop offset="50%" stop-color="#ffffff" stop-opacity="1" />
            <stop offset="100%" stop-color="#ffffff" stop-opacity="0" />
          </linearGradient>
        </defs>
        <path
          d="M100,0 Q136,180 500,200"
          stroke="url(#gradientStroke)"
          fill="none"
          strokeWidth="3"
        />
      </svg>
      <svg
        style={{
          position: "absolute",
          marginTop: "0vh",
          zIndex:'-1'
        }}
        width="100%"
        height="100vh"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="60%">
            <stop offset="0%" stop-color="#ffffff" stop-opacity="0" />
            <stop offset="50%" stop-color="#ffffff" stop-opacity="1" />
            <stop offset="100%" stop-color="#ffffff" stop-opacity="0" />
          </linearGradient>
        </defs>
        <path
          d="M1500,0 Q10,0 20,400"
          stroke="url(#gradientStroke)"
          fill="none"
          strokeWidth="3"
        />
      </svg>
      <div className="cartcontent">
        <div className="header">
          <span> Wallet Balance</span>
          <IoMdEye color="white" size={20} />
        </div>

        <div className="total">
          <span>USDT</span>
          <span>1,505.24</span>
        </div>

        <div className="buttons">
          <button>Receive</button>
          <button>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Cardwallet;
