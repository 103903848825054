import React from "react";
import "./pagethree.scss";
import { TbHexagonFilled } from "react-icons/tb";
import { FaSignal } from "react-icons/fa";
import { IoIosWifi } from "react-icons/io";
import { IoBatteryFull } from "react-icons/io5";

import send from "../../image/send.png";
import swap from "../../image/swapok.png";
import qrcode from "../../image/qrcode.png";
import Cardwallet from "../cardwallet/cardwallet";

const Pagethree = () => {
  return (
    <div className="threecontainer">
      <div className="qrcode">
        <img src={qrcode} width={100} style={{marginBottom:'20px'}} alt=""/>
      </div>
      <div className="hexagon">
        <TbHexagonFilled size={"20vmax"} className="hexagon-icon" />
        <img src={send} className="icon" alt="send" />
      </div>
      <div className="text">
        <span>SEND CRYPTOCURRENCY WITH EASE</span>
        <span>
          Effortlessly move your digital assets with fast and simplicity.
        </span>
      </div>
      {/* <div className="iphone">
        <div className="iphonespan">
          <span>9 : 05</span>
          <div className="notch"></div>
          <FaSignal style={{ marginRight: "6px" }} size={20}/>
          <IoIosWifi style={{ marginRight: "6px" }} size={20}/>
          <IoBatteryFull size={20}/>
        </div>

        <div className="iphoneheader">
          <div style={{display:'flex', flexDirection:'column',  textAlign: 'left' }}>
            <span>Wallet</span>
            <span>Mainnet</span>
          </div>

          <div>
            <img src={swap} alt="" width={26} style={{marginLeft:'10px', marginTop:'13px'}}/>
          </div>
        </div>
        
        <div className="cardwalletcontainer">
          <Cardwallet />
        </div>
      </div> */}
    </div>
  );
};

export default Pagethree;
