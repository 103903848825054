import React, { useEffect, useRef } from "react";
import "./pagesecond.scss";
import money from "../../image/dollar.png";
import card from "../../image/credit-card.png";
import Cardwallet from "../cardwallet/cardwallet";

const Pagesecond = () => {
  const cardRef = useRef(null); // Create a ref for the card

  useEffect(() => {
    let toggled = false; // Flag to track toggle state

    const handleClick = () => {
      toggled = !toggled; // Toggle the state

      const moneyEl = document.querySelector(".money img");
      const cardCreditEl = document.querySelector(".card-credit img");
      const texts = document.querySelectorAll(".texts span");
      const total = document.querySelector(".total span:nth-child(2)");

      const transformSettings = toggled
        ? "translateY(100px) scale(0.5)"
        : "translateY(0) scale(1)";
      const opacityValue = toggled ? 0 : 1;
      const textTransform = toggled
        ? "translateY(-200px) scale(0.5)"
        : "translateY(0)";

      if (moneyEl && cardCreditEl && texts.length > 0) {
        moneyEl.style.transform = `rotate(39deg) ${transformSettings}`;
        moneyEl.style.opacity = opacityValue;
        cardCreditEl.style.transform = transformSettings;
        cardCreditEl.style.opacity = opacityValue;

        texts.forEach((text) => {
          text.style.transform = textTransform;
          text.style.opacity = opacityValue;
        });

        total.textContent = toggled ? "200,505.24" : "1,505.24";
      }
    };

    // Attach the event listener to the card element
    const cardEl = cardRef.current;
    if (cardEl) {
      cardEl.addEventListener("click", handleClick);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (cardEl) {
        cardEl.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <div className="secondcontainer">
       
      <div className="cardwalletdiv">
        <div className="money">
          <img src={money} alt="" className="money-img"/>
        </div>
        <div className="credit">
          <img src={card} alt="" className="card-img"/>
        </div>
        <div className="cardsec">
          <Cardwallet/>
        </div>
      </div>
      <div className="tex">
        <span>RECEIVE CRYPTOCURRENCY WITH EASE</span>
        <span>
          Simplify your transactions and enhance your wallet with seamless
          crypto transfers.
        </span>
      </div>
    </div>
  );
};

export default Pagesecond;
