import React from "react";
import "./home.scss";
import { IoCard } from "react-icons/io5";
import { TbHexagonFilled } from "react-icons/tb";
import send from "../../image/send.png";
import pentagon from "../../image/pentagon.png";
import statistic from "../../image/statistic.png";

export const Home = () => {
  return (
    <div className="homecontainer">
      <div className="blur">
        <div className="texts">
          <span>Discover Crypto Freedom </span>
          <span>Never Before</span>
          <span>
            Experience the pinnacle of efficiency with our platform: The smart
            way to store and transfer digital currency.Streamlined, secure, and
            user-centric, we redefine what it means to have true crypto freedom.
            Join a world where every transaction is swift, every storage
            solution is robust, and your financial autonomy is paramount.
          </span>
          <button>Access Your Wallet</button>
        </div>
        <div className="hexagonone">
          <TbHexagonFilled size={'8.6vmax'} className="hexagon-icon" />
          <IoCard size={'4vmax'} className="inner-icon" color="white" />
        </div>
        <div className="hexagontwo">
          <TbHexagonFilled size={'9vmax'} className="hexagon-icon" />
          <img src={send} width={'80vmax'} className="inner-icon" alt="send" />
        </div>

        <div className="hexagonthree">
          <TbHexagonFilled size={'8.6vmax'} className="hexagon-icon" />
          <img
            src={pentagon}
            width={'90vmax'}
            className="inner-icon"
            alt="pentagon"
          />
          <img
            src={statistic}
            width={'40vmax'}
            className="inner-staticicon"
            alt="statistic"
          />
        </div>
      </div>
    </div>
  );
};
