import './App.css';
import { Home } from './component/home/home';
import Pagefourth from './component/pagefourth/pagefourth';
import Pagesecond from './component/pagesecond/pagesecond';
import Pagethree from './component/pagethree/pagethree';

function App() {
  return (
    <div className="App">
      <Home className="apphome"/>
      <Pagesecond className="pagesecond" />
      <Pagethree />
      <Pagefourth />
    </div>
  );
}

export default App;
