import React from "react";
import "./pagefourth.scss";
import appstore from "../../image/app-store.png";
import googlestore from "../../image/google-play.png";
import send from "../../image/sendicon.png";
import receive from "../../image/receive.png";
import swapicon from "../../image/swap.png";
import stake from "../../image/stake.png";
import vote from "../../image/vote.png";
import swapupdown from "../../image/swapupdown.png";
import pluscircle from "../../image/pluscircle.png";

import trx from "../../image/trx.png";
import usdt from "../../image/usdt.png";
import usdd from "../../image/usdd.png";
import htx from "../../image/htx.png";
import usdc from "../../image/usdc.png";
import btt from "../../image/btt.png";

import navbar1 from "../../image/navbar1.png";
import navbar2 from "../../image/navbar2.png";
import navbar3 from "../../image/navbar3.png";
import navbar4 from "../../image/navbar4.png";

import { FaSignal } from "react-icons/fa";
import { IoIosWifi } from "react-icons/io";
import { IoBatteryFull } from "react-icons/io5";

import swap from "../../image/swapok.png";
import Cardwallet from "../cardwallet/cardwallet";

const Pagefourth = () => {
  const images = [
    { src: send, caption: "Send " },
    { src: receive, caption: "Receive" },
    { src: swapicon, caption: "Swap" },
    { src: stake, caption: "Stake" },
    { src: vote, caption: "Vote" },
  ];

  const coinsData = [
    { id: 1, image: trx, title: "TRX", subtitle: "$0.121039" },
    { id: 2, image: usdt, title: "USDT", subtitle: "$1.000124" },
    { id: 3, image: usdd, title: "USDD", subtitle: "$1" },
    { id: 4, image: htx, title: "HTX", subtitle: "$0.00000168" },
    { id: 5, image: usdc, title: "USDC", subtitle: "$1.0090" },
    { id: 6, image: btt, title: "BTT", subtitle: "$0.000001431" },
  ];

  const navbar = [
    { id: 1, image: navbar1, title: "Assets" },
    { id: 2, image: navbar2, title: "Markets" },
    { id: 3, image: navbar3, title: "Discover" },
    { id: 4, image: navbar4, title: "Me" },
  ];

  return (
    <div className="fourthcontainer">
      <div className="text">
        <span>DOWNLOAD THE APP</span>
      </div>
      <div className="iphone">
        <div className="iphonespan">
          <span>9 : 05</span>
          <div className="notch"></div>
          <FaSignal style={{ marginRight: "6px" }} size={20} />
          <IoIosWifi style={{ marginRight: "6px" }} size={20} />
          <IoBatteryFull size={20} />
        </div>
        <div className="iphoneheader">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div style={{display:'flex', alignItems:'center'}}>
              <span>Wallet</span>
              <img
                src={swap}
                alt=""
                style={{ marginLeft: "10px", width:'23px', height:'20px'}}
              />
            </div>
            <span>Mainnet</span>
          </div>
        </div>
        <div className="cardwalletcontainer">
          <Cardwallet />
        </div>

        <div className="iphoneicons">
          {images.map((image, index) => (
            <div key={index} className="image-container">
              <img
                src={image.src}
                alt={`Resim ${index + 1}`}
                width={22}
                height={18}
              />
              <span className="caption">{image.caption}</span>
            </div>
          ))}
        </div>

        <div className="line"></div>

        <div className="assetdiv">
          <div style={{ display: "flex", gap: "1vmax" }}>
            <span>Assets</span>
            <span>Collectibles</span>
          </div>
          <div
            style={{ display: "flex", gap: "0.4vmax", alignItems: "center" }}
          >
            <img src={swapupdown} alt="" />
            <span>Preference</span>
            <img src={pluscircle} alt="" width={15} height={15} />
          </div>
        </div>

        <div className="coins">
          {coinsData.map((coin) => (
            <div key={coin.id} className="coin-box">
              <img src={coin.image} alt={coin.title} />
              <span style={{ fontWeight: "700", fontSize: "0.7rem" }}>
                {coin.title}
              </span>
              <span>{coin.subtitle}</span>
            </div>
          ))}
        </div>

        <div className="bottomnavbar">
          {navbar.map((navbar) => (
            <div key={navbar.id} className="navbardiv">
              <img src={navbar.image} alt={navbar.title} />
              <span style={{ fontWeight: "200", fontSize: "0.7rem" }}>
                {navbar.title}
              </span>
              <span>{navbar.subtitle}</span>
            </div>
          ))}
        </div>

        <div className="bottomline"></div>
      </div>

      <div className="download">
        <div className="appstore">
          <div className="iconapp">
            <img src={appstore} width="25" alt="" />
          </div>
          <div className="appstoretext">
            <span>Coming Soon on</span>
            <span>App Store</span>
          </div>
        </div>
        <div className="playstore">
          <div className="iconapp">
            <img src={googlestore} width="25" alt="" />
          </div>
          <div className="playstoretext">
            <span>Coming Soon on</span>
            <span>Google Play</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagefourth;
